import {Container, CssBaseline} from "@material-ui/core";
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';

import {theme} from "../../theme.mui";

import HeaderComponent from "../../components/header/header.component";
import HeroComponent from "../hero/hero.component";
import ServicesComponent from "../services/services.component";
import AboutComponent from "../about/about.component";
import PortfolioComponent from "../portfolio/portfolio.component";
import ContactComponent from "../contact/contact.component";
import FooterComponent from "../footer/footer.component";

const useStyles = makeStyles({
    footer: {
        backgroundColor: '#161A2E'
    }
})

function AppComponent() {
    const classes = useStyles()
  return (
    <>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <HeaderComponent />
            <main>
                <HeroComponent />
                <Container>
                    <ServicesComponent />
                    <AboutComponent />
                    <PortfolioComponent />
                </Container>
                <ContactComponent />
            </main>
            <footer className={classes.footer}>
                <FooterComponent />
            </footer>
        </ThemeProvider>
    </>
  );
}

export default AppComponent;
