import React from 'react';
import { Grid, Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TitleComponent from "../../components/title/title.component";
import {useScrollEffect} from "../../hooks/use-section-scroll";

const useStyles = makeStyles( theme => ({
    root: {
        margin: '3rem auto',
    },
    content: {
        marginBottom: '1rem',
        fontSize: "0.9375rem"
    },
    item: {
        width: "80%",
        margin: "auto"
    },
    image: {
        display: 'block',
        height: "150px",
        width: "auto",
        maxWidth: "500px",
        margin: "auto",
        borderRadius: "8px",

        [theme.breakpoints.up('sm')]: {
            height: "120px",
        }
    }
}))

const PortfolioComponent = () => {
    const classes = useStyles()
    const { portfolioSectionRef } = useScrollEffect()
    return (
        <div className={classes.root}>
            <TitleComponent ref={portfolioSectionRef}  mainText='My' styledText='Work' align='center' />
            <Typography component='p' variant="body2" className={classes.content} align='center'>
                In my experience as a professional within this industry, I have had the privilege to service the following organizations with their card processing.
            </Typography>
            <Grid container spacing={1} justifyContent="center">
                <Grid item sm={4} className={classes.item}>
                    <img src="images/houston_rockets.png" alt="Houston Rockets" className={classes.image} />
                </Grid>
                <Grid item sm={4} className={classes.item}>
                    <img src="images/woodlands_resort.png" alt="Woodlands Resort" className={classes.image} />
                </Grid>
                <Grid item sm={4} className={classes.item}>
                    <img src="images/gallery_furniture.png" alt="Gallery Furniture" className={classes.image} />
                </Grid>
            </Grid>
        </div>
    );
};

export default PortfolioComponent;