import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#EDF1FF'
                }
            }
        },
        MuiButton: {
            containedPrimary: {
                backgroundColor: '#18328A',
                height: '2.5rem'
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#18328A'
            }
        }
    }
})