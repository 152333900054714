import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
    root: {
        padding: "1rem",
        width: '100%',
        margin: "auto"
    },
    footerTitle: {
        marginBottom: '1rem',
        textAlign: "center",
        color: "white"
    },
    content: {
        color: "whitesmoke",
        textAlign: "center",
        fontSize: '0.75rem',
    },
})

const FooterComponent = () => {
    const classes = useStyles()
    return (
        <Grid container className={classes.root} spacing={3}>
            <Grid container item xs={12} sm={6} direction='column' justifyContent='space-between' style={{ order: '2' }}>
                    <Typography variant='subtitle2' component='p' className={classes.footerTitle}> JEPayments</Typography>
                    <Typography component='small' variant="body2" className={classes.content}>Jeremy is an independent sales agent for Flashbanc LLC.</Typography>
                    <Typography component='p' variant='body2' className={classes.content}>
                       &copy; - { new Date().getFullYear() }. All rights reserved
                    </Typography>
            </Grid>
            <Grid item xs={12} sm={6} style={{ order: '1' }}>
                <Typography variant='subtitle2' component='h3' className={classes.footerTitle}>Contact Me</Typography>
                <Typography component='p' variant="body2" className={classes.content}>9550 Meyer Forest Dr, Houston, Texas, 77096</Typography>
                <Typography component='p' variant="body2" className={classes.content}>(903) 267-5249</Typography>
                <Typography component='p' variant="body2" className={classes.content}>jeremy@jepayments.com</Typography>
            </Grid>
        </Grid>

    );
};

export default FooterComponent;