import React from 'react';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";

import ServiceCardComponent from "../../components/service-card/service-card.component";
import UseScrollAnimation from "../../hooks/use-scroll-animation";
import TitleComponent from "../../components/title/title.component";

import { DATA } from "../../data";
import {useScrollEffect} from "../../hooks/use-section-scroll";


const useStyles = makeStyles(theme => ({
    section: {
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    icon: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '9.375rem',
        height: '9.375rem',

        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '6rem',
            height: '6rem',
        }
    },

}))

const ServicesComponent = () => {
    const [ control, ref ] = UseScrollAnimation()
    const { servicesSectionRef } = useScrollEffect()

    const classes = useStyles()

    const gridContainer = {
        visible: {
            opacity: 1,
            transition: {
                delay: 0.3,
                staggerChildren: 0.3,
                ease: "easeInOut",
                type: "tween",
                duration: 0.5,
            },
        },
        hidden: {
            opacity: 0,
            transition: {
                when: "afterChildren",
            },
        },
    }

    const gridItem = {
        visible: { opacity: 1, transition: { duration: 1.6 }, },
        hidden: { opacity: 0, }
    }

    return (
        <>
            <Grid
                container
                spacing={2}
                justifyContent='space-between'
                className={classes.section}
                initial="hidden"
                animate={control}
                ref={ref}
                variants={gridContainer}
                component={motion.div}
            >

                <TitleComponent ref={servicesSectionRef} align='center' mainText='Our' styledText='services' />

                {
                    Object.keys(DATA).map((entry, index) => {
                        const { title, shortDescription, image, extraText } = DATA[entry]
                        return (
                            <Grid key={index} item xs={12} sm={6} md={3} variants={gridItem} component={motion.div}>
                                <ServiceCardComponent
                                    title={title}
                                    content={shortDescription}
                                    icon={image}
                                    extraText={extraText}
                                />
                            </Grid>
                        );
                    })
                }
            </Grid>
        </>
    );
};

export default ServicesComponent;