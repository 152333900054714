import React, {useState} from 'react';
import {Button, Card, CardContent, CardMedia, Collapse, Fade, Typography, useMediaQuery} from "@material-ui/core";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import { motion } from "framer-motion";

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: '0.625rem',
        width: 'auto',
        minHeight: '21.80rem',
    },
    imageContainer: {
        margin: 'auto',
        height: '140px',
        width: 'auto'
    },
    cardTitle: {
        fontSize: '1.125rem',
        fontWeight: 400,
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#000',
        marginBottom: theme.spacing(2),
        userSelect: 'none',

        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    cardBody: {
        fontSize: "0.8125rem",
        letterSpacing: '1.5px',
        lineHeight: '1.5em',
        textAlign: 'justify',
        color: '#001247',
        userSelect: 'none',
        marginBottom: theme.spacing(1),

        '& span button': {
            padding: 0,
            color: '#127DC0'
        },

        [theme.breakpoints.down('md')]: {
            fontSize: '0.875rem',
            lineHeight: 'unset',
        }
    },
    cardActions: {
        padding: 0,

        '& button': {
            padding: 0,
            color: '#001247'
        },
    },
}))

const ServiceCardComponent = ({ title, content, icon, extraText }) => {
    const classes = useStyles()
    const theme = useTheme()
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    const [readMore, setReadMore] = useState(false);

    const handleClick = () => {
        setReadMore(!readMore)
    }

    return (
        <Collapse
            className={classes.root}
            in={readMore} collapsedSize={isTablet ? 380: 350 } timeout={100}
            transition={{ ease: "easeInOut"}}
            whileHover={{ scale: 1.08, transition: { duration: 0.250 }, }}
            component={motion.div}
        >
            <Card className={classes.root}>
                <CardMedia
                    src={icon}
                    component='img'
                    className={classes.imageContainer}
                />
                <CardContent style={{ paddingLeft: '24px', paddingRight: '24px' }}>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component={motion.h2}
                            animate={{ opacity: 1}}
                            initial={{ opacity: 0 }}
                            transition={{ duration: 2 }}
                            className={classes.cardTitle}
                        >
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component={motion.p}
                            animate={{ opacity: 1}}
                            initial={{ opacity: 0 }}
                            transition={{ duration: 2 }}
                            className={classes.cardBody}
                        >
                            {content}
                            <br /> <span><Button  onClick={handleClick}>
                                {`${readMore ? 'Read less' : 'Read more'}`}
                            </Button></span>
                        </Typography>
                        <Fade in={readMore} mountOnEnter unmountOnExit timeout={500}>
                            <Typography  className={classes.cardBody} variant="body2" color="textSecondary">
                                {extraText}
                            </Typography>
                        </Fade>
                    </CardContent>
            </Card>
        </Collapse>
    );
};

export default ServiceCardComponent;