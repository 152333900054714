import React from 'react';
import FormInputComponent from "../form-input/form-input.component";
import {Box, Button, Fade, Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import UseFormController from "../../hooks/use-form-controller";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        '& .MuiButton-containedSizeSmall, .MuiFormControl-root': {
            margin: "0.5rem 0"
        }
    }
})

const FormComponent = () => {
    const classes = useStyles()
    const { handleChange, handleSubmit, errors, values, response, setResponse } = UseFormController()


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            setResponse(false);
        }

        setResponse(false);
    };

    return (
        <Box component='form' autoComplete="off" onSubmit={handleSubmit} noValidate className={classes.root}>
            <FormInputComponent
                label="Full Name"
                id="id_fullName"
                name="fullName"
                value={values.fullName}
                onChange={handleChange}
                {...(errors.fullName && {error: true, helperText: `${errors.fullName}`})}
            />
            <FormInputComponent
                label="Email"
                id="id_email"
                helperText="e.g. email@example.com"
                name="email"
                value={values.email}
                onChange={handleChange}
                {...(errors.email && {error: true, helperText: `${errors.email}`})}
            />
            <FormInputComponent
                label="Subject"
                id="id_subject"
                name="subject"
                value={values.subject}
                onChange={handleChange}
                {...(errors.subject && {error: true, helperText: `${errors.subject}`})}
            />
            <FormInputComponent
                label="Message"
                id="id_message"
                name="message"
                value={values.message}
                onChange={handleChange}
                {...(errors.message && {error: true, helperText: `${errors.message}`})}
                multiline
            />
            <Button
                type='submit'
                variant='contained'
                color='primary'
                size='small'
            >
                Send message
            </Button>
            <Snackbar open={response} autoHideDuration={3000} onClose={handleClose} TransitionComponent={Fade}>
                <MuiAlert elevation={6} variant="filled" severity="success" onClose={handleClose}>
                    Email message successfully sent
                </MuiAlert>
            </Snackbar>
        </Box>
    );
};

export default FormComponent;