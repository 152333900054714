import React from 'react';
import { Container, Grid, Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import FormComponent from "../../components/form/form.component";
import TitleComponent from "../../components/title/title.component";
import {useScrollEffect} from "../../hooks/use-section-scroll";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'white',
        paddingTop: '2rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
    },
    image: {
        height: 'auto',
        width: '30rem',

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    }
}))

const ContactComponent = () => {
    const classes = useStyles()
    const { contactSectionRef } = useScrollEffect()

    return (
        <Grid container spacing={1} justifyContent='center' className={classes.container}>
            <Container p={1}>
                <TitleComponent ref={contactSectionRef} mainText='Contact' styledText='me' align='center' />
                <Typography component='p' variant="body2" align='center' style={{ fontSize: "0.9375rem"}}>
                    Drop me an email and I will get back to you
                    to discuss your contract-free, personalized solution that is tailored to your
                    business.
                </Typography>
                <Grid item container justifyContent='center'>
                    <Grid item xs={12} sm={6}>
                        <img className={classes.image} src='images/contact_us.png' alt='contact us'/>
                    </Grid>
                    <Grid container item xs={12} md={6} direction="column" justifyContent="space-between">
                        <FormComponent/>

                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default ContactComponent;