import React from 'react';
import {Box, Button, Container, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useScrollEffect} from "../../hooks/use-section-scroll";


const useStyles = makeStyles(theme => ({
    section: {
        marginBottom: theme.spacing(3),
        position: 'relative',

        '&:hover .hoverImage': {
            transform: 'scale(1.05)'
        },
    },
    container: {
        backgroundImage: `linear-gradient(45deg, rgba(
                0, 0, 0, 0.62), rgba(
                  0, 0, 0, 0.60)), url('/images/credit-card-payment-wide-2.png')`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: 'cover',
        height: "100vh",
        width: '100%',
        transition: "all 700ms ease-in-out",

        [theme.breakpoints.down('md')]: {
            background: `linear-gradient(45deg, rgba(
                0, 0, 0, 0.62), rgba(
                  0, 0, 0, 0.60)), url('/images/credit-card-payment-mobile.png')`,
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundSize: 'cover',
            height: "55vh",
        },

        [theme.breakpoints.between('sm', "md")]: {
            height: "65vh",
        }

    },
    hero: {
        backgroundColor: 'whitesmoke',
        color: '#001247'
    },
    heroContent: {
        maxWidth: "50%",
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        marginTop: theme.spacing(2),

        "& > button": {
            width: "50%",
            fontSize: '0.75rem',

            [theme.breakpoints.down('sm')]: {
                width: "unset",
                fontSize: '10px',
            }
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: "unset",
            fontSize: '0.75rem',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '0.875rem',
            maxWidth: "unset",
            fontSize: '0.625rem',
        }
    },
    heroHeading: {
        color: "white",
        fontSize: "2.25rem",
        textTransform: "capitalize",

        [theme.breakpoints.down('sm')]: {
            fontSize: "1.125rem",
            marginBottom: '0.875rem',
            fontWeight: 500
        },

        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }

    },
    heroText: {
        marginBottom: theme.spacing(5),
        fontSize: "1.125rem",
        color: "white",

        [theme.breakpoints.down('sm')]: {
            fontSize: "0.875rem",
            marginBottom: theme.spacing(2),
        },

        [theme.breakpoints.down('xs')]: {
            fontSize: "0.8125rem",
            marginBottom: theme.spacing(1),
        }
    },
}))

const HeroComponent = () => {
    const classes = useStyles();
    const { contactSectionRef, scrollToSection } = useScrollEffect()

    const handleClick = event => {
      event.preventDefault();
        scrollToSection(contactSectionRef)
    }

    return (
        <section className={classes.section}>
            <div className={`hoverImage ${classes.container}`}  />
            <Container  maxWidth={false}>
                <Box p={1} className={classes.heroContent}>
                    <Container>
                        <Typography variant='h2' gutterBottom className={classes.heroHeading}>
                            Focus on your business, I take care of the payments
                        </Typography>
                        <Typography variant='body1' gutterBottom align='justify' className={classes.heroText}>
                            In my years of working in payment processing, I have heard and seen the complaints and worries of business owners when it comes to finding the right payment solution.
                            This led me to enter the sales industry with a mission to help business owners find the right solution so they can scale their business.
                            I pride myself in offering fair, transparent pricing while holding my customers to no contract.
                        </Typography>

                        <Button  size='small' variant='contained' color='primary' onClick={handleClick}>Get in touch now</Button>
                    </Container>
                </Box>
            </Container>
        </section>
    );
};

export default HeroComponent;