import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppComponent from './containers/app/App.component';
import SectionScrollProvider from "./hooks/use-section-scroll";

import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!
smoothscroll.polyfill();


ReactDOM.render(
    <SectionScrollProvider>
        <AppComponent />
    </SectionScrollProvider>,
  document.getElementById('root')
);
