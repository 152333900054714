import {useState} from "react";
import emailjs from 'emailjs-com';

const FORM_INPUT_VALUES = {
    fullName: "",
    email: "",
    subject: "",
    message: "",
}

const UseFormController = () => {
    const EMAIL_REGEX = '^[a-zA-Z0-9.!#$%&\'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$'
    const STRING_REGEX = '^[^-\\s][a-zA-Z0-9_\\s-.!#$%&\'*+/=?^_`{|}~-]+$'

    const [errors, setErrors] = useState({});
    const [values, setValues] = useState(FORM_INPUT_VALUES);
    const [response, setResponse] = useState(null);


    const validate = inputData => {
        let temp = { ...errors }

        if("email" in inputData) {
            temp.email = validateEmail(inputData["email"])
        }
        if("fullName" in inputData) {
            temp.fullName = validateInputString(inputData["fullName"])
        }

        if("subject" in inputData) {
            temp.subject = validateInputString(inputData["subject"])
        }

        if("message" in inputData) {
            temp.message = validateInputString(inputData["message"])
        }

        setErrors({ ...temp })
    }

    const isFormValid = (inputValues = values) => {
        return (
            inputValues.email &&
            inputValues.fullName &&
            inputValues.message &&
            inputValues.subject &&
            Object.values(errors).every(error => error === "")
        )
    }

    const validateEmail = emailString => {
        const regExPattern = new RegExp(EMAIL_REGEX)
        const isValid = regExPattern.test(emailString)
        return isValid ? "" : "Please enter a valid email"
    }

    const validateInputString = inputString => {
        const regExPattern = new RegExp(STRING_REGEX)
        return inputString.length >= 5 && regExPattern.test(inputString) ? "" : "Please enter at least 5 characters"
    }

    const handleChange = evt => {
        evt.preventDefault()
        const { name, value } = evt.target

        setValues({
            ...values,
            [name]: value
        })
        validate({ [name]: value })
    }

    const handleSubmit = async evt => {
        evt.preventDefault()
        if(isFormValid()) {
            // Send email logic
            emailjs.init("user_jcm0PM5Qb35LOp9aeGliZ");
            /*
            emailjs.send("je_payments","template_uzmm06m",{
                    email: values.email,
               });
           */
            var res = await emailjs.send("service_o04zx6f","template_uzmm06m",{
                email: values.email, message: values.message, full_name: values.fullName, subject: values.subject
            });

            setResponse(res.status === 200)
            setValues(FORM_INPUT_VALUES)
        }
    }

    return {
        values,
        errors,
        response,
        setResponse,
        isFormValid,
        validate,
        handleChange,
        handleSubmit
    }
}

export default UseFormController;