import { useEffect } from 'react';
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const UseScrollAnimation = () => {
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if(inView) {
            control.start("visible")
        }
    }, [control, inView]);


    return [ control, ref ];
};

export default UseScrollAnimation;