import React from 'react';
import {Typography, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    heading: {
        display: 'inline-block',
        width: 'fit-content',
        fontWeight: 400,
        position: "relative",
        textTransform: 'uppercase',
        marginBottom: '3rem',

        '& span': {
            color: '#809FFF'
        },

        '&:after': {
            position: "absolute",
            content: '""',
            height: '2px',
            bottom: '-8px',
            margin: '0 auto',
            left: '0',
            right: '0',
            width: '50%',
            background: '#000066'
        },

        [theme.breakpoints.down('sm')]: {
            fontSize: '1.875rem',
            marginBottom: theme.spacing(2),
        }
    }
}))

const TitleComponent = React.forwardRef(({ mainText, styledText, ...other }, ref) => {
    const classes = useStyles()

    return (
        <Grid xs={12} item container justifyContent='center' alignItems='center' direction='column'>
            <Typography
                variant='h4'
                className={classes.heading}
                ref={ref}
                gutterBottom
                { ...other }
            >
                { mainText } <span>{ styledText }</span>
            </Typography>
        </Grid>
    );
});

export default TitleComponent;