import {useState, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';
import {
    AppBar,
    Box,
    Button,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
    MenuItem,
    IconButton,
    Popper,
    Paper,
    ClickAwayListener,
    MenuList,
    Fade,
    Container
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {useScrollEffect} from "../../hooks/use-section-scroll";

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
        fontSize: '1.75rem',
        color: 'white',
    },
    logo: {
        height: '52px',
        width: "auto",
        cursor: "pointer"
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    text: {
        color: '#FFF',
        transition: 'color 200ms ease-in-out',
        '&:hover': {
            opacity: '0.7'
        }
    },
    [theme.breakpoints.down('sm')]: {
        title: {
            fontSize: '1rem'
        },
        container: {
            padding: '0.625rem'
        }
    }
}));

const HeaderComponent = () => {
    const classes = useStyles();
    const { homeSectionRef, servicesSectionRef, aboutSectionRef, portfolioSectionRef, scrollToSection } = useScrollEffect()
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleScroll = ref => {
        scrollToSection(ref)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };


    return (
        <AppBar position="fixed" elevation={0} style={{ backgroundColor: `${trigger? '#161A2E' : 'transparent'}`, transition: trigger ? '0.3s' : '0.5s', height: '55px'}} >
            <Toolbar>
                <Container className={classes.container}>
                    <img src="/images/JE-Logo.png" onClick={() => handleScroll(homeSectionRef)} alt="JEPayments" className={classes.logo} ref={homeSectionRef} />
                    { isMobile ? (
                        <>
                            <IconButton
                                onClick={handleToggle}
                                ref={anchorRef}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true">
                                <MenuIcon style={{ color: 'white' }} />
                            </IconButton>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Fade
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                                    <MenuItem onClick={() => handleScroll(homeSectionRef)}>Home</MenuItem>
                                                    <MenuItem onClick={() => handleScroll(servicesSectionRef)}>Services</MenuItem>
                                                    <MenuItem onClick={() => handleScroll(aboutSectionRef)}>About</MenuItem>
                                                    <MenuItem onClick={() => handleScroll(portfolioSectionRef)}>Portfolio</MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </>
                    ) : (
                        <Box m={2}>
                            <Button onClick={() => handleScroll(homeSectionRef)} className={classes.text}>
                                Home
                            </Button>
                            <Button onClick={() => handleScroll(servicesSectionRef)} className={classes.text}>
                                Services
                            </Button>
                            <Button onClick={() => handleScroll(aboutSectionRef)} className={classes.text}>
                                About
                            </Button>
                            <Button onClick={() => handleScroll(portfolioSectionRef)} className={classes.text}>
                                Portfolio
                            </Button>
                        </Box>
                    )
                    }
                </Container>
            </Toolbar>
        </AppBar>
    );
}

export default HeaderComponent;
