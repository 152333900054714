import React from 'react';
import {TextField} from "@material-ui/core";

const FormInputComponent = ({ label, value, onChange, multiline= false, ...other }) =>
    (
        <TextField
            label={label}
            value={value}
            onChange={onChange}
            fullWidth
            multiline
            rows={multiline ? 5 : 1}
            required
            {...other}
        />
    );

export default FormInputComponent;