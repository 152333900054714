import React, {useRef, createContext, useContext} from 'react';

const ScrollContext = createContext(null)

export const useScrollEffect = () => useContext(ScrollContext)

export default function SectionScrollProvider ({ children }) {
    const homeSectionRef = useRef()
    const servicesSectionRef = useRef()
    const aboutSectionRef = useRef()
    const portfolioSectionRef = useRef()
    const contactSectionRef = useRef()

    const scrollToSection = ref => {
        window.scroll({
            top: ref.current.offsetTop,
            behavior: "smooth"
        })
    }

    return (
        <ScrollContext.Provider value={{ homeSectionRef, contactSectionRef, servicesSectionRef, aboutSectionRef, portfolioSectionRef, scrollToSection }}>
            { children }
        </ScrollContext.Provider>
    )
};

