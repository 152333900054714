import { v4 as uuidv4 } from 'uuid';

export const DATA = {
    ordering: {
        id: uuidv4(),
        title: "Seamless Ordering",
        shortDescription: "We help businesses streamline their ordering process to give customers a pleasant and seamless experience.",
        extraText: "I can help setup your business to take orders via your website, phone, or social media like Facebook. In addition, I can help your business install POS systems for secure payments and easier checkout for your customers.",
        image: "/images/ordering-icon.svg"
    },
    discount:{
        id: uuidv4(),
        title: "Cash Discount",
        shortDescription: "A simple and legal way to help you save up to 90% in credit card processing fees!",
        extraText: "Your customers choose their cash payment method, reducing your processing fees and increasing your bottom-line profits.  I handle the setup, in-store signage, and employee training so you can focus on running your business.",
        image: "/images/discount-icon.png"
    },
    checkout: {
        id: uuidv4(),
        title: "Quick Checkout",
        shortDescription: "Our system gives you, the business owner, multiple ways to improve your business.",
        extraText: "We offer a seamless integration of online and mobile app ordering to match your brand. You can send push notifications with offers, rewards, and promotions and schedule discounts. Finally, you can utilize our analytic report tool to make sense of your data for key decision making. ",
        image: "/images/card-checkout-icon.svg"
    },
    analytics: {
        id: uuidv4(),
        title: "Dashboard Analytics",
        shortDescription: "With our system you have the flexibility and convenience of processing payments and refunds wherever you happen to be.",
        extraText: "You can accept credit/debit card payments, send digital receipts, and collect contact information for customer engagement. Just log into our web dashboard on your device and you’re ready for business.",
        image: "/images/analytics-icon.svg"
    }
}