import React from 'react';
import { motion } from "framer-motion";

import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import UseScrollAnimation from '../../hooks/use-scroll-animation'
import {useScrollEffect} from "../../hooks/use-section-scroll";

import FormatQuoteSharpIcon from '@material-ui/icons/FormatQuoteSharp';
import TitleComponent from "../../components/title/title.component";

const useStyles = makeStyles(theme => ({
    body: {
        fontSize: '0.9375rem',
        textAlign: 'justify'
    },
    image: {
        display: 'block',
        margin: 'auto',
        width: '100%',
        height: 'auto',
        borderRadius: '12px',
        transform: 'scaleX(-1)',

        [theme.breakpoints.down('xs')]: {
            width: '6.25rem',
            borderRadius: '50%'
        }

    },
    icon: {
        transform: 'scaleX(-1)',
    },
}))

const AboutComponent = () => {
    const classes = useStyles()
    const [ control, ref ] = UseScrollAnimation()
    const { aboutSectionRef } = useScrollEffect()

    const container = {
        hidden: {
            opacity: 0,
            x: -300
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                x: { type: 'tween', duration: 1 },
                default: { duration: 2 }
            }
        }
    }

    const quoteText = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    }

    return (
        <Grid container justifyContent='center' direction='column' alignItems='center'>
            <TitleComponent ref={aboutSectionRef} mainText='Who' styledText='I am' align='center' />
            <Container>
                <Grid container item justifyContent='space-around'>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.image} src="/images/jeremy.jpeg" alt="Jeremy Eackles"/>
                    </Grid>
                    <Grid item xs={12} sm={6} variants={container} initial='hidden' animate={control} component={motion.div} ref={ref}>
                        <motion.blockquote animate='visible' initial='hidden' variants={quoteText}>
                            <Typography variant='body2' className={classes.body}>
                                <FormatQuoteSharpIcon className={classes.icon} />
                                I am a results-driven payment professional with vast experience in dealing with small and large Independent Sales Organizations and merchants.<br /><br />
                                My goal is to empower business owners and executives to focus on driving growth and profitability through payment processing services, business management software, and data intelligence in one holistic platform.<br /><br />
                            </Typography>
                            <Typography variant='body2' className={classes.body}>I have expertise in selling Point of Sales and terminal solutions, with the intent of establishing better payments strategies to ensure better business efficiency and overall increase in profitability.</Typography> <br />
                            <Typography variant='body2' className={classes.body}>If you are a business owner or executive in need of a robust payment solution for your business, let's talk!</Typography>
                        </motion.blockquote>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
};

export default AboutComponent;